import React from 'react'
import Head from '../components/head'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import TextHighLight from '../components/textHighlight'
import '../styles/404.css'

//check to swap with stateless component
const NotFound = () => {
  return (
    <Layout page="Work">
      <Head title="404 Not Found" />
      <section className="nf-content content-gutter">
        <h1>Page Not Found</h1>
        <p className="super-paragraph">
          <Link to="/">
            <TextHighLight>Head Home</TextHighLight>
          </Link>
        </p>
      </section>
    </Layout>
  )
}

export default NotFound
